import Experience from '../Experience.js'
import Environment from './Environment.js'
import Fox from './Fox.js'
// import TV from './TV.js'
import Floor from './Building/woodFloor.js'
import Walls from './Building/walls.js'
import Rug from './Objects/rug.js'
import Table from './Objects/table.js'
import Chair from './Objects/chair.js'
import Beanbag from './Objects/beanbag.js'
import Posters from './Objects/posters.js'
import Lamp2 from './Objects/lamp2.js'
import Lamp1 from './Objects/lamp1.js'
import Ottoman from './Objects/ottoman.js'
import Plant_Orchid from './Plants/orchid.js'
import Basketball from './Objects/basketball.js'
import LeftJordan from './Objects/leftJordan.js'
import RightJordan from './Objects/rightJordan.js'
import Cups from './Objects/cups.js'
import Headphones from './Objects/headphones.js'
import Glasses from './Objects/glasses.js'
import Plant_Banjo from './Plants/banjo.js'
import Plant_Monstera from './Plants/monstera.js'
import Plant_Pot2 from './Plants/pot2.js'
import Plant_Pot1 from './Plants/pot1.js'
import Plant_Succulent1 from './Plants/succulent1.js'
import Plant_Succulent2 from './Plants/succulent2.js'
import Plant_Succulent3 from './Plants/succulent3.js'
import Vinyl from './Objects/vinyl.js'
import Book_Top from './Objects/booktop.js'
import Book_Table from './Objects/booktable.js'
import Book_Stacks from './Objects/bookstacks.js'
import Magazine from './Objects/magazine.js'
import Pen from './Objects/pen.js'
import LeftSpeaker from './Objects/speakerL.js'
import RightSpeaker from './Objects/speakerR.js'
import Turntable from './Objects/turntable.js'
import TV from './Objects/tv.js'
import Screen from './Objects/screen.js'
import Mirror from './Objects/mirror.js'
import Bed from './Objects/bed.js'
import TurntableBB from './Objects/turntableBB.js'
import TableBB from './Objects/tableBB.js'
import TVBB from './Objects/tvBB.js'
import Info from './Objects/info.js'
import MainBook from './Objects/mainbook.js'
import MainBookBB from './Objects/mainbookBB.js'

export default class World {
    constructor() {
        this.experience = new Experience()

        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Wait for resources
        this.resources.on('ready', () => { 
            // Setup
            this.floor = new Floor()
            this.walls = new Walls()
            this.rug = new Rug()
            
            this.chair = new Chair()
            this.beanbag = new Beanbag()
            this.posters = new Posters()
            this.lamp2 = new Lamp2()
            this.lamp1 = new Lamp1()
            this.ottoman = new Ottoman()
            this.basketball = new Basketball()
            this.leftJordan = new LeftJordan()
            this.rightJordan = new RightJordan()
            this.cups = new Cups()
            this.headphones = new Headphones()
            this.glasses = new Glasses()
            this.vinyl = new Vinyl()
            this.booktop = new Book_Top()
            this.booktable = new Book_Table()
            this.bookstacks = new Book_Stacks()
            this.magazine = new Magazine()
            this.pen = new Pen()
            this.speakerL = new LeftSpeaker()
            this.speakerR = new RightSpeaker()
            this.info = new Info()
            this.mainbook = new MainBook()
            
            
            this.mirror = new Mirror()
            this.bed = new Bed()

            this.orchid = new Plant_Orchid()
            this.banjo = new Plant_Banjo()
            this.monstera = new Plant_Monstera()
            this.pot2 = new Plant_Pot2()
            this.pot1 = new Plant_Pot1()
            this.succulent1 = new Plant_Succulent1()
            this.succulent2 = new Plant_Succulent2()
            this.succulent3 = new Plant_Succulent3()

            this.tv = new TV()
            this.screen = new Screen()
            this.turntable = new Turntable()
            this.turntableBB = new TurntableBB()
            this.table = new Table()
            this.tableBB = new TableBB()
            this.tvBB = new TVBB()
            this.mainbookBB = new MainBookBB()

            this.environment = new Environment()
        })
    }

    update() {
        if (this.fox) {
            this.fox.update()
        }
    }
}