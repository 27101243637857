export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture', 
        path: [
            'textures/environmentMap/px.jpg',
            'textures/environmentMap/nx.jpg',
            'textures/environmentMap/py.jpg',
            'textures/environmentMap/ny.jpg',
            'textures/environmentMap/pz.jpg',
            'textures/environmentMap/nz.jpg'
        ]
    },
    {
        name: 'grassColorTexture',
        type: 'texture',
        path: 'textures/dirt/color.jpg'
    },
    {
        name: 'grassNormalTexture',
        type: 'texture',
        path: 'textures/dirt/normal.jpg'
    },
    {
        name: 'foxModel',
        type: 'gltfModel',
        path: 'models/Fox/glTF/Fox.gltf'
    },
    {
        name: 'floor',
        type: 'gltfModel',
        path: 'models/Building/floor.gltf'
    },
    {
        name: 'floorTexture',
        type: 'texture',
        path: 'textures/buildingTextures/baked-Floor2.jpg'
    },
    {
        name: 'walls',
        type: 'gltfModel',
        path: 'models/Building/walls.gltf'
    },
    {
        name: 'wallTexture',
        type: 'texture',
        path: 'textures/buildingTextures/baked-Walls3.jpg'
    },
    {
        name: 'rug',
        type: 'gltfModel',
        path: 'models/Building/rug.gltf'
    },
    {
        name: 'rugTexture',
        type: 'texture',
        path: 'textures/buildingTextures/baked-Rug.jpg'
    },
    {
        name: 'table',
        type: 'gltfModel',
        path: 'models/Objects/table.gltf'
    },
    {
        name: 'tableTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Table.jpg'
    },
    {
        name: 'chair',
        type: 'gltfModel',
        path: 'models/Objects/chair.gltf'
    },
    {
        name: 'chairTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Chair.jpg'
    },
    {
        name: 'beanbag',
        type: 'gltfModel',
        path: 'models/Objects/beanbag.gltf'
    },
    {
        name: 'beanbagTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Beanbag.jpg'
    },
    {
        name: 'lamp2',
        type: 'gltfModel',
        path: 'models/Objects/lamp2.gltf'
    },
    {
        name: 'lamp2Texture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Lamp2.jpg'
    },
    {
        name: 'ottoman',
        type: 'gltfModel',
        path: 'models/Objects/ottoman.gltf'
    },
    {
        name: 'ottomanTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Ottoman.jpg'
    },
    {
        name: 'mirror',
        type: 'gltfModel',
        path: 'models/Objects/mirror.gltf'
    },
    {
        name: 'mirrorTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Mirror.jpg'
    },
    {
        name: 'orchid',
        type: 'gltfModel',
        path: 'models/Plants/plant-orchid.gltf'
    },
    {
        name: 'orchidTexture',
        type: 'texture',
        path: 'textures/plantTextures/baked-PlantOrchid.jpg'
    },
    {
        name: 'posters',
        type: 'gltfModel',
        path: 'models/Objects/posters.gltf'
    },
    {
        name: 'postersTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Posters.jpg'
    },
    {
        name: 'basketball',
        type: 'gltfModel',
        path: 'models/Objects/basketball.gltf'
    },
    {
        name: 'basketballTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Basketball.jpg'
    },
    {
        name: 'leftJordan',
        type: 'gltfModel',
        path: 'models/Objects/leftJordan.gltf'
    },
    {
        name: 'leftJordanTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-LeftJordan.jpg'
    },
    {
        name: 'cups',
        type: 'gltfModel',
        path: 'models/Objects/cups.gltf'
    },
    {
        name: 'cupTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Cup.jpg'
    },
    {
        name: 'headphones',
        type: 'gltfModel',
        path: 'models/Objects/headphones.gltf'
    },
    {
        name: 'headphoneTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Headphones.jpg'
    },
    {
        name: 'glasses',
        type: 'gltfModel',
        path: 'models/Objects/glasses.gltf'
    },
    {
        name: 'glassesTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Glasses.jpg'
    },
    {
        name: 'banjo',
        type: 'gltfModel',
        path: 'models/Plants/plant-banjo.gltf'
    },
    {
        name: 'banjoTexture',
        type: 'texture',
        path: 'textures/plantTextures/baked-PlantBanjo.jpg'
    },
    {
        name: 'monstera',
        type: 'gltfModel',
        path: 'models/Plants/plant-monstera.gltf'
    },
    {
        name: 'monsteraTexture',
        type: 'texture',
        path: 'textures/plantTextures/baked-PlantMonstera.jpg'
    },
    {
        name: 'pot2',
        type: 'gltfModel',
        path: 'models/Plants/plant-pot2.gltf'
    },
    {
        name: 'pot2Texture',
        type: 'texture',
        path: 'textures/plantTextures/baked-PlantPot2.jpg'
    },
    {
        name: 'pot1',
        type: 'gltfModel',
        path: 'models/Plants/plant-pot1.gltf'
    },
    {
        name: 'pot1Texture',
        type: 'texture',
        path: 'textures/plantTextures/baked-PlantPot1.jpg'
    },
    {
        name: 'vinyl',
        type: 'gltfModel',
        path: 'models/Objects/vinyl.gltf'
    },
    {
        name: 'vinylTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Vinyl.jpg'
    },
    {
        name: 'booktop',
        type: 'gltfModel',
        path: 'models/Objects/booktop.gltf'
    },
    {
        name: 'booktopTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-BookTop.jpg'
    },
    {
        name: 'booktable',
        type: 'gltfModel',
        path: 'models/Objects/booktable.gltf'
    },
    {
        name: 'booktableTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-BookTable.jpg'
    },
    {
        name: 'bookstacks',
        type: 'gltfModel',
        path: 'models/Objects/bookstacks.gltf'
    },
    {
        name: 'bookstackTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-BookStacks.jpg'
    },
    {
        name: 'magazine',
        type: 'gltfModel',
        path: 'models/Objects/magazine.gltf'
    },
    {
        name: 'magazineTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Magazine.jpg'
    },
    {
        name: 'pen',
        type: 'gltfModel',
        path: 'models/Objects/pen.gltf'
    },
    {
        name: 'penTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Pen.jpg'
    },
    {
        name: 'succulent1',
        type: 'gltfModel',
        path: 'models/Plants/plant-succulent1.gltf'
    },
    {
        name: 'succulent1Texture',
        type: 'texture',
        path: 'textures/plantTextures/baked-PlantSucculent1.jpg'
    },
    {
        name: 'succulent2',
        type: 'gltfModel',
        path: 'models/Plants/plant-succulent2.gltf'
    },
    {
        name: 'succulent2Texture',
        type: 'texture',
        path: 'textures/plantTextures/baked-PlantSucculent2.jpg'
    },
    {
        name: 'succulent3',
        type: 'gltfModel',
        path: 'models/Plants/plant-succulent3.gltf'
    },
    {
        name: 'succulent3Texture',
        type: 'texture',
        path: 'textures/plantTextures/baked-PlantSucculent3.jpg'
    },
    {
        name: 'rightJordan',
        type: 'gltfModel',
        path: 'models/Objects/rightJordan.gltf'
    },
    {
        name: 'rightJordanTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-RightJordan.jpg'
    },
    {
        name: 'speakerL',
        type: 'gltfModel',
        path: 'models/Objects/speakerL.gltf'
    },
    {
        name: 'speakerLTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-SpeakerL.jpg'
    },
    {
        name: 'speakerR',
        type: 'gltfModel',
        path: 'models/Objects/speakerR.gltf'
    },
    {
        name: 'speakerRTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-SpeakerR.jpg'
    },
    {
        name: 'turntable',
        type: 'gltfModel',
        path: 'models/Objects/turntable.gltf'
    },
    {
        name: 'turntableTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Turntable.jpg'
    },
    {
        name: 'lamp1',
        type: 'gltfModel',
        path: 'models/Objects/lamp1.gltf'
    },
    {
        name: 'lamp1Texture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Lamp1.jpg'
    },
    {
        name: 'tv',
        type: 'gltfModel',
        path: 'models/Objects/tv.gltf'
    },
    {
        name: 'tvTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-TV.jpg'
    },
    {
        name: 'screen',
        type: 'gltfModel',
        path: 'models/Objects/screen.gltf'
    },
    {
        name: 'screenTexture',
        type: 'video',
        path: 'textures/objectTextures/flcl.mp4'
    },
    {
        name: 'bed',
        type: 'gltfModel',
        path: 'models/Objects/bed.gltf'
    },
    {
        name: 'bedTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Bed.jpg'
    },
    {
        name: 'turntableBB',
        type: 'gltfModel',
        path: 'models/Objects/turntableBB.gltf'
    },
    {
        name: 'tableBB',
        type: 'gltfModel',
        path: 'models/Objects/tableBB.gltf'
    },
    {
        name: 'tvBB',
        type: 'gltfModel',
        path: 'models/Objects/tvBB.gltf'
    },
    {
        name: 'info',
        type: 'gltfModel',
        path: 'models/Objects/info.gltf'
    },
    {
        name: 'infoTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-Info.jpg'
    },
    {
        name: 'mainBook',
        type: 'gltfModel',
        path: 'models/Objects/mainbook.gltf'
    },
    {
        name: 'mainBookTexture',
        type: 'texture',
        path: 'textures/objectTextures/baked-MainBook.jpg'
    },
    {
        name: 'mainbookBB',
        type: 'gltfModel',
        path: 'models/Objects/mainbookBB.gltf'
    }


        
]