import * as THREE from 'three'
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'
import Resources from './Utils/Resources.js'
import Debug from './Utils/Debug.js'
import sources from './sources.js'
import { gsap } from 'gsap'


let instance = null

export default class Experience {
    constructor(canvas) {

        // Singleton
        if (instance) {
            return instance
        }
        instance = this

        // Global access
        window.experience = this

        // Options
        this.canvas = canvas
        this.scene = new THREE.Scene()
        this.setOverlay()

        // Setup
        this.resources = new Resources(sources)

        this.resources.on('resourcesLoaded', () => {
            gsap.to(this.overlayMaterial.uniforms.uAlpha, { duration: 5, value: 0 });
        });


        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        

        this.camera = new Camera()
        this.renderer = new Renderer()
        this.world = new World()

        this.clickableObjects = []
        this.objectsOffsets = new Map()

        // Sizes resize event
        this.sizes.on('resize', () => {
            this.resize()
        })

        // Time tick event
        this.time.on('tick', () => {
            this.update()
        })
        
    }

    setOverlay() {
        const overlayLoader = new THREE.TextureLoader()
        const overlayTexture = overlayLoader.load('/textures/objectTextures/overlay.png')

        this.overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
        this.overlayMaterial = new THREE.ShaderMaterial({
            transparent: true,
            uniforms: {
                uAlpha: { value: 1.0 },
                uTexture: { value: overlayTexture }
            },
            vertexShader: `
                varying vec2 vUv;

                void main() {
                    vUv = uv;
                    gl_Position = vec4(position, 1.0);
                }
            `,
            fragmentShader: `
                uniform float uAlpha;
                uniform sampler2D uTexture;
                varying vec2 vUv;

                void main() {
                    vec4 textureColor = texture2D(uTexture, vUv);
                    gl_FragColor = vec4(textureColor.rgb, uAlpha * textureColor.a); // Apply alpha to texture
                }
            `
        });

        this.overlay = new THREE.Mesh(this.overlayGeometry, this.overlayMaterial)
        this.scene.add(this.overlay)
    }

    resize() {
        this.camera.resize()
        this.renderer.resize()
    }

    update() {
        this.camera.update()
        this.world.update()
        this.renderer.update()
    }

    destroy() {
        this.sizes.off('resize')
        this.time.off('tick')

        // Traverse scene
        this.scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.geometry.dispose()

                for (const key in child.material) {
                    const value = child.material[key]

                    if (value && typeof value.dispose === 'function') {
                        value.dispose()
                    }
                }
            }
        })
        this.camera.controls.dispose()
        this.renderer.instance.dispose()

        if (this.debug.active) {
            this.debug.ui.destroy()
        }
    }

}