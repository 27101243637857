export default [
    new Audio('/sounds/1-yukika-neon.mp3'),
    new Audio('/sounds/2-xg-leftright.mp3'),
    new Audio('/sounds/3-frederic-oddloop.mp3'),
    new Audio('/sounds/4-xg-shootingstar.mp3'),
    new Audio('/sounds/5-yukika-insomnia.mp3'),
    new Audio('/sounds/6-yoasobi-idol.mp3'),
    new Audio('/sounds/7-triples-rising.mp3'),
    new Audio('/sounds/8-ano-chu.mp3'),
    new Audio('/sounds/9-chelmico-player.mp3'),
    new Audio('/sounds/10-fiftyfifty-tellme.mp3'),
    new Audio('/sounds/11-newjeans-supershy.mp3'),
    new Audio('/sounds/12-yukika-cherriesjubiles.mp3'),
    new Audio('/sounds/13-dprian-noblueberries.mp3'),
    new Audio('/sounds/14-anri-shynessboy.mp3'),
    new Audio('/sounds/15-loona-girlfront.mp3'),
    new Audio('/sounds/16-asiankungfugeneration-rere.mp3'),
]