import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Experience from './Experience.js'
import TWEEN from '@tweenjs/tween.js';
import { gsap } from 'gsap'

export default class Camera {
    constructor() {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.debug = this.experience.debug
        this.world = this.experience.world
        this.resources = this.experience.resources
        this.time = this.experience.time

        /*this.setOverlay()

        this.resources.on('resourcesLoaded', () => {
            gsap.to(this.overlayMaterial.uniforms.uAlpha, { duration: 5, value: 0 });
        });

        */

        this.startParallaxing = false

        this.setInstance()
        // this.setOrbitControls()

        this.raycaster = new THREE.Raycaster()
        this.mouse = new THREE.Vector2(0, 0)
        this.originalCameraPosition = new THREE.Vector3(12.516504299709798, 22.349578680560796, 17.138468096547555)
        this.originalCameraRotation = new THREE.Vector3(-0.9001696698835023, 0.6071043945019664, 0.6234698453157272)

        this.canvas.addEventListener('click', (event) => this.onMouseClick(event), false);

        this.parallaxStrength = 0.7
        this.parallaxSpeed = 5
        this.parallaxDamping = 0.95
        this.parallaxBasePosition = new THREE.Vector3()
        this.parallaxBasePosition.copy(this.originalCameraPosition);
        this.canvas.addEventListener('mousemove', (event) => this.onMouseMove(event), false);




        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder('Camera');

            // Position
            const cameraPositionFolder = this.debugFolder.addFolder('Position');
            cameraPositionFolder.add(this.instance.position, 'x').min(-100).max(100).step(0.1).name('x-position').onChange(() => this.controls.update());
            cameraPositionFolder.add(this.instance.position, 'y').min(-100).max(100).step(0.1).name('y-position').onChange(() => this.controls.update());
            cameraPositionFolder.add(this.instance.position, 'z').min(-100).max(100).step(0.1).name('z-position').onChange(() => this.controls.update());

            // Rotation
            const cameraRotationFolder = this.debugFolder.addFolder('Rotation');
            cameraRotationFolder.add(this.instance.rotation, 'x').min(0).max(2 * Math.PI).step(0.1).name('x-rotation').onChange(() => this.controls.update());
            cameraRotationFolder.add(this.instance.rotation, 'y').min(0).max(2 * Math.PI).step(0.1).name('y-rotation').onChange(() => this.controls.update());
            cameraRotationFolder.add(this.instance.rotation, 'z').min(0).max(2 * Math.PI).step(0.1).name('z-rotation').onChange(() => this.controls.update());
        }
    }


    setInstance() {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 100)
        this.instance.position.set(12.516504299709798, 22.349578680560796, 17.138468096547555)
        this.instance.rotation.set(-0.9001696698835023, 0.6071043945019664, 0.6234698453157272)

        this.scene.add(this.instance)
        this.startParallaxing = true
    }

    setOrbitControls() {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
    }

    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    onMouseClick(event) {
        // Convert the mouse position to normalized device coordinates (NDC)
        this.mouse.x = (event.clientX / this.sizes.width) * 2 - 1;
        this.mouse.y = -(event.clientY / this.sizes.height) * 2 + 1;

        

        // Update the raycaster with the camera and mouse position
        this.raycaster.setFromCamera(this.mouse, this.instance);

        // Calculate objects intersecting the picking ray
        const intersects = this.raycaster.intersectObjects(this.scene.children, true);

        if (intersects.length > 0 && this.experience.clickableObjects.includes(intersects[0].object)) {
            this.focusOnObject(intersects[0].object);
        } else {
            this.resetCamera()
        }

    }

    onMouseMove(event) {
        // Normalize mouse coordinates
        this.mouse.x = (event.clientX / this.sizes.width) * 2 - 1;
        this.mouse.y = - (event.clientY / this.sizes.height) * 2 + 1;

        
    }

    screenVectorsEqual(v1) {
        const v2 = new THREE.Vector3(5, 2, -4)

        return v1.x === v2.x && v1.y === v2.y && v1.z === v2.z;
    }

    ttVectorsEqual(v1) {
        const v2 = new THREE.Vector3(2, 3, 2)

        return v1.x === v2.x && v1.y === v2.y && v1.z === v2.z;
    }

    mirrorVectorsEqual(v1) {
        const v2 = new THREE.Vector3(9, 9, 9)

        return v1.x === v2.x && v1.y === v2.y && v1.z === v2.z;
    }

    posterVectorsEqual(v1) {
        const v2 = new THREE.Vector3(8, 8, 8)

        return v1.x === v2.x && v1.y === v2.y && v1.z === v2.z;
    }

    basketballVectorsEqual(v1) {
        const v2 = new THREE.Vector3(7, 7, 7)

        return v1.x === v2.x && v1.y === v2.y && v1.z === v2.z;
    }

    bedVectorsEqual(v1) {
        const v2 = new THREE.Vector3(6, 6, 6)

        return v1.x === v2.x && v1.y === v2.y && v1.z === v2.z;
    }

    beanbagVectorsEqual(v1) {
        const v2 = new THREE.Vector3(5, 5, 5)

        return v1.x === v2.x && v1.y === v2.y && v1.z === v2.z;
    }

    rugVectorsEqual(v1) {
        const v2 = new THREE.Vector3(4, 4, 4)

        return v1.x === v2.x && v1.y === v2.y && v1.z === v2.z;
    }

    focusOnObject(object) {
        // this.parallaxBasePosition = this.instance.position;

        // Determine the new camera position and the controls target
        const offset = this.experience.objectsOffsets.get(object)
        if (!offset) return

        if (this.screenVectorsEqual(offset)) {
            const video = this.resources.items.screenTexture;
            if (video) video.muted = false;
        } else if (this.ttVectorsEqual(offset)) {
            this.resources.toggleMute()
        } else if (this.mirrorVectorsEqual(offset)) {
            window.open('https://github.com/Balance321/Twitter-Project', '_blank');
            return
        } else if (this.posterVectorsEqual(offset)) { 
            const pdf = '/pdfs/russell_lacara_248final.mp4'
            window.open(pdf, '_blank');
            return
        } else if (this.basketballVectorsEqual(offset)) {
            const pdf = '/pdfs/Isotropic Remeshing Writeup.pdf'
            window.open(pdf, '_blank');
            return
        } else if (this.bedVectorsEqual(offset)) {
            const pdf = '/pdfs/Russell-Lacara_Resume.pdf'
            window.open(pdf, '_blank');
            return
        } else if (this.beanbagVectorsEqual(offset)) {
            window.open('https://www.linkedin.com/in/russell-lacara/', '_blank');
            return
        } else if (this.rugVectorsEqual(offset)) {
            const pdf = '/pdfs/Credits.pdf'
            window.open(pdf, '_blank');
            return
        }

        this.isFocusing = true
        this.parallaxStrength = 0.07

        const newTargetPosition = object.position;
        const newCameraPosition = newTargetPosition.clone().add(offset);

        // Calculate the target rotation using a quaternion
        const targetQuaternion = new THREE.Quaternion().setFromRotationMatrix(
            new THREE.Matrix4().lookAt(newCameraPosition, newTargetPosition, this.instance.up)
        );

        // Tween the camera position
        new TWEEN.Tween(this.instance.position)
            .to({ x: newCameraPosition.x, y: newCameraPosition.y, z: newCameraPosition.z }, 2000)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onComplete(() => {
                this.parallaxBasePosition.copy(this.instance.position);
                this.isFocusing = false;
                // Reset mouse to avoid drift
                this.mouse.set(0, 0);
            })
            .start();

        // Tween the camera rotation using quaternion
        new TWEEN.Tween(this.instance.quaternion)
            .to(targetQuaternion, 2000)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .start();

        // Tween the controls target
        new TWEEN.Tween(this.controls.target)
            .to({ x: newTargetPosition.x, y: newTargetPosition.y, z: newTargetPosition.z }, 2000)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onUpdate(() => this.controls.update())
            .start();


    }


    resetCamera() {
        // this.parallaxBasePosition = this.originalCameraPosition;
        TWEEN.removeAll(); // Stop any ongoing transitions
        this.parallaxStrength = 0.7

        // Tween the camera position
        new TWEEN.Tween(this.instance.position)
            .to({
                x: this.originalCameraPosition.x,
                y: this.originalCameraPosition.y,
                z: this.originalCameraPosition.z
            }, 2000)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                this.parallaxBasePosition.copy(this.originalCameraPosition);
                this.isFocusing = false;
                // Reset mouse to avoid drift
                this.mouse.set(0, 0);
            })
            .start();

        // Calculate the original rotation as a quaternion
        const originalQuaternion = new THREE.Quaternion().setFromEuler(
            new THREE.Euler(
                this.originalCameraRotation.x,
                this.originalCameraRotation.y,
                this.originalCameraRotation.z,
                'XYZ' // Assuming the original rotation order is XYZ
            )
        );

        // Tween the camera rotation
        new TWEEN.Tween(this.instance.quaternion)
            .to(originalQuaternion, 2000)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start()

        const video = this.resources.items.screenTexture;
        if (video) video.muted = true;

        this.resources.reset()
    }



    update() {
        //this.controls.update()

        if (this.startParallaxing && !this.isFocusing) { 
            
            const deltaTime = this.experience.time.delta * 0.001

            const targetParallaxX = this.parallaxBasePosition.x + this.mouse.x * this.parallaxStrength;
            const targetParallaxY = this.parallaxBasePosition.y + this.mouse.y * this.parallaxStrength;

            this.instance.position.x += (targetParallaxX - this.instance.position.x) * deltaTime * this.parallaxSpeed;
            this.instance.position.y += (targetParallaxY - this.instance.position.y) * deltaTime * this.parallaxSpeed;

            this.mouse.x *= this.parallaxDamping;
            this.mouse.y *= this.parallaxDamping;

        }
        
        TWEEN.update()
    }
}