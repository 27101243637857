import * as THREE from 'three' 
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import EventEmitter from './EventEmitter.js'
import playList from '../playlist.js'
import { gsap } from 'gsap'


export default class Resources extends EventEmitter {
    constructor(sources) {
        super()

        // Options
        this.sources = sources


        // Setup
        this.items = {}
        this.toLoad = this.sources.length
        this.loaded = 0


        this.setLoaders()
        this.startLoading()
        this.playlist = playList

        this.currentTrackIndex = 0
        this.isMuted = true
        this.initialClick = true
        this.playNextTrack()
    }


    playNextTrack() {
        // Stop the current track if it's playing
        if (this.playlist[this.currentTrackIndex]) {
            this.playlist[this.currentTrackIndex].pause();
        }

        // Move to the next track
        this.currentTrackIndex = (this.currentTrackIndex + 1) % this.playlist.length;
        const currentTrack = this.playlist[this.currentTrackIndex];
        currentTrack.volume = 0.05
        currentTrack.muted = this.isMuted;
        currentTrack.play();

        // Set up to play the next track when this one ends
        currentTrack.onended = () => this.playNextTrack();
    }

    toggleMute() {
        if (this.initialClick) {
            // If this is the first click, start playing the current track
            this.isMuted = false;
            if (this.playlist[this.currentTrackIndex]) {
                this.playlist[this.currentTrackIndex].muted = this.isMuted;
            }
            this.initialClick = false; // Update the flag after the first click
        } else if (!this.isMuted) {
            // If currently not muted, skip to the next track
            this.playNextTrack();
        } else {
            // Otherwise, just toggle the mute state
            this.isMuted = !this.isMuted;
            if (this.playlist[this.currentTrackIndex]) {
                this.playlist[this.currentTrackIndex].muted = this.isMuted;
            }
        }
    }

    reset() {
        this.isMuted = true;
        if (this.playlist[this.currentTrackIndex]) {
            this.playlist[this.currentTrackIndex].muted = this.isMuted;
        }
        // Any other reset logic...
    }

    setLoaders() {
        this.loaders = {}
        const loadingBarElement = document.querySelector('.loading-bar')

        this.loadingManager = new THREE.LoadingManager(
            () => {
                this.trigger('resourcesLoaded');
                loadingBarElement.classList.add('ended')
                loadingBarElement.style.transform = ''
            },
            (itemUrl, itemsLoaded, itemsTotal) => {
                const progressRatio = itemsLoaded / itemsTotal
                loadingBarElement.style.transform = `scaleX(${progressRatio})`

            }
        );

        this.loaders.gltfLoader = new GLTFLoader(this.loadingManager)
        this.loaders.dracoLoader = new DRACOLoader(this.loadingManager)
        this.loaders.dracoLoader.setDecoderPath('draco/')
        this.loaders.gltfLoader.setDRACOLoader(this.loaders.dracoLoader)

        this.loaders.textureLoader = new THREE.TextureLoader(this.loadingManager)
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(this.loadingManager)
    }

    startLoading() {
        // Load each source
        for (const source of this.sources) {
            if (source.type === 'gltfModel') {
                this.loaders.gltfLoader.load(
                    source.path, (file) => {
                        this.sourceLoaded(source, file)
                    }
                )
            } else if (source.type === 'texture') {
                this.loaders.textureLoader.load(
                    source.path, (file) => {
                        this.sourceLoaded(source, file)
                    }
                )
            } else if (source.type === 'cubeTexture') {
                this.loaders.cubeTextureLoader.load(
                    source.path, (file) => {
                        this.sourceLoaded(source, file)
                    }
                )
            } else if (source.type == 'video') {
                const video = document.createElement('video')
                video.src = source.path
                video.load()

                this.screenTexture = video
                video.volume = 0.1
                video.loop = true
                video.muted = true
                video.play()
                this.sourceLoaded(source, video)
            }
        }
    }

    sourceLoaded(source, file) {
        this.items[source.name] = file
        this.loaded++

        if (this.loaded === this.toLoad) {
            this.trigger('ready')
        }
    }

}